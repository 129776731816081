import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { useIntl } from "gatsby-plugin-react-intl"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import axios from "axios"

const ContactPage = ({ data: { page, bgImage } }) => {
  const intl = useIntl()
  const pluginImage = getImage(bgImage.childImageSharp.gatsbyImageData)
  const image = convertToBgImage(pluginImage)

  const [nameValue, setNameValue] = useState("")
  const [emailValue, setEmailValue] = useState("")
  const [messageValue, setMessageValue] = useState("")
  const [formResp, setFormResp] = useState({
    status: "",
    response: "",
    body_response: "",
  })

  const { executeRecaptcha } = useGoogleReCaptcha()
  const [token, setToken] = useState("")

  return (
    <Layout className="overflow-x-hidden break-all">
      <h1 className="hidden">{page?.title}</h1>
      <Seo seoData={page?.seo} />
      <BackgroundImage
        Tag="div"
        // Spread bgImage into BackgroundImage:
        {...image}
        preserveStackingContext
        id="ContactPage"
        className="bg-fixed bg-no-repeat bg-bottom bg-black bg-opacity-50 bg-blend-multiply flex justify-center items-center"
      >
        <h2 className="text-white text-6xl font-PTSerif font-bold italic py-20 text-center">
          {intl.formatMessage({ id: "Contact Us" })}
        </h2>
      </BackgroundImage>
      <div>
        <div className="w-10/12 mx-auto flex flex-col lg:flex-row gap-y-5 lg:gap-x-5 py-8">
          <div className="w-full">
            <h2 className="font-PTSerif font-bold text-5xl text-black pb-5">
              {intl.formatMessage({ id: "How to Find Us" })}
            </h2>
            <p className="pb-3 text-themeGray-200 text-base font-Lato mx-auto">
              {intl.formatMessage({ id: "contactPara1" })}
            </p>
            <div className="w-full text-themeGray-200 font-Lato text-base">
              <div>
                <div className="flex items-center pb-4">
                  <span className="pr-5 text-[#666c84]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 202.592 202.592"
                      width="1rem"
                      height="1rem"
                      className="fill-current"
                    >
                      <path d="M198.048 160.105l-31.286-31.29c-6.231-6.206-16.552-6.016-23.001.433L128 145.009a649.985 649.985 0 01-3.11-1.732c-9.953-5.515-23.577-13.074-37.914-27.421C72.599 101.48 65.03 87.834 59.5 77.874c-.587-1.056-1.145-2.072-1.696-3.038l10.579-10.565 5.2-5.207c6.46-6.46 6.639-16.778.419-23.001L42.715 4.769c-6.216-6.216-16.541-6.027-23.001.433l-8.818 8.868.243.24c-2.956 3.772-5.429 8.124-7.265 12.816-1.696 4.466-2.752 8.729-3.235 12.998-4.13 34.25 11.52 65.55 53.994 108.028 58.711 58.707 106.027 54.273 108.067 54.055 4.449-.53 8.707-1.593 13.038-3.275 4.652-1.818 9.001-4.284 12.769-7.233l.193.168 8.933-8.747c6.446-6.459 6.632-16.777.415-23.015zm-7.365 16.059l-3.937 3.93-1.568 1.507c-2.469 2.387-6.743 5.74-12.984 8.181-3.543 1.364-7.036 2.24-10.59 2.663-.447.043-44.95 3.84-100.029-51.235C14.743 94.38 7.238 67.395 10.384 41.259c.394-3.464 1.263-6.95 2.652-10.593 2.462-6.277 5.812-10.547 8.181-13.02l5.443-5.497c2.623-2.63 6.714-2.831 9.112-.433l31.286 31.286c2.394 2.401 2.205 6.492-.422 9.13L45.507 73.24l1.95 3.282c1.084 1.829 2.23 3.879 3.454 6.106 5.812 10.482 13.764 24.83 29.121 40.173 15.317 15.325 29.644 23.27 40.094 29.067 2.258 1.249 4.32 2.398 6.17 3.5l3.289 1.95 21.115-21.122c2.634-2.623 6.739-2.817 9.137-.426l31.272 31.279c2.391 2.397 2.201 6.488-.426 9.115z" />
                    </svg>
                  </span>
                  <span>
                    <a
                      className="font-Lato text-base text-themeGray-200 hover:text-themeAccent transition delay-150 duration-300 ease-in-out"
                      href="tel:516-505-0044"
                    >
                      516.505.0044
                    </a>
                  </span>
                </div>
                <div className="flex items-center pb-4">
                  <span className="pr-5 text-[#666c84]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 508 508"
                      width="1rem"
                      height="1rem"
                      className="fill-current"
                    >
                      <path d="M465.75 101.4h-22.6V14.1c0-7.8-6.3-14.1-14.1-14.1h-214.5c-7.8 0-14.1 6.3-14.1 14.1v87.3H42.35c-23.3 0-42.3 19-42.3 42.3v321.9c0 23.4 19 42.4 42.3 42.4h423.3c23.3 0 42.3-19 42.3-42.3v-322c.1-23.3-18.9-42.3-42.2-42.3zm-237.1-73.2h186.3v128.3h-186.3V28.2zm251.3 437.5h-.1c0 7.8-6.3 14.1-14.1 14.1H42.35c-7.8 0-14.1-6.3-14.1-14.1v-322c0-7.8 6.3-14.1 14.1-14.1h158.1v26.9h-.3c-7.8 0-14.1 6.3-14.1 14.1s6.3 14.1 14.1 14.1h243.4c7.8 0 14.1-6.3 14.1-14.1s-6.3-14.1-14.1-14.1h-.3v-26.9h22.6c7.8 0 14.1 6.3 14.1 14.1v322z" />
                      <path d="M150.65 156.5h-80c-7.8 0-14.1 6.3-14.1 14.1v268.1c0 7.8 6.3 14.1 14.1 14.1h80c7.8 0 14.1-6.3 14.1-14.1v-268c0-7.8-6.3-14.2-14.1-14.2zm-14.1 268.1H84.75V184.8h51.8v239.8zM382.95 52.5h-122.4c-7.8 0-14.1 6.3-14.1 14.1s6.3 14.1 14.1 14.1h122.4c7.8 0 14.1-6.3 14.1-14.1s-6.3-14.1-14.1-14.1zM382.95 104.1h-122.4c-7.8 0-14.1 6.3-14.1 14.1s6.3 14.1 14.1 14.1h122.4c7.8 0 14.1-6.3 14.1-14.1.1-7.8-6.3-14.1-14.1-14.1zM443.45 205.5h-243.3c-7.8 0-14.1 6.3-14.1 14.1v74.2c0 7.8 6.3 14.1 14.1 14.1h243.4c7.8 0 14.1-6.3 14.1-14.1v-74.2c-.1-7.8-6.4-14.1-14.2-14.1zm-14.1 74.3h-215.1v-46h215.1v46zM224.75 334.4h-24.6c-7.8 0-14.1 6.3-14.1 14.1s6.3 14.1 14.1 14.1h24.6c7.8 0 14.1-6.3 14.1-14.1s-6.3-14.1-14.1-14.1zM297.65 334.4h-24.6c-7.8 0-14.1 6.3-14.1 14.1s6.3 14.1 14.1 14.1h24.6c7.8 0 14.1-6.3 14.1-14.1s-6.3-14.1-14.1-14.1zM370.55 334.4h-24.6c-7.8 0-14.1 6.3-14.1 14.1s6.3 14.1 14.1 14.1h24.6c7.8 0 14.1-6.3 14.1-14.1s-6.3-14.1-14.1-14.1zM443.45 334.4h-24.6c-7.8 0-14.1 6.3-14.1 14.1s6.3 14.1 14.1 14.1h24.6c7.8 0 14.1-6.3 14.1-14.1s-6.3-14.1-14.1-14.1zM224.75 379h-24.6c-7.8 0-14.1 6.3-14.1 14.1s6.3 14.1 14.1 14.1h24.6c7.8 0 14.1-6.3 14.1-14.1s-6.3-14.1-14.1-14.1zM297.65 379h-24.6c-7.8 0-14.1 6.3-14.1 14.1s6.3 14.1 14.1 14.1h24.6c7.8 0 14.1-6.3 14.1-14.1s-6.3-14.1-14.1-14.1zM370.55 379h-24.6c-7.8 0-14.1 6.3-14.1 14.1s6.3 14.1 14.1 14.1h24.6c7.8 0 14.1-6.3 14.1-14.1s-6.3-14.1-14.1-14.1zM443.45 379h-24.6c-7.8 0-14.1 6.3-14.1 14.1s6.3 14.1 14.1 14.1h24.6c7.8 0 14.1-6.3 14.1-14.1s-6.3-14.1-14.1-14.1zM224.75 423.6h-24.6c-7.8 0-14.1 6.3-14.1 14.1s6.3 14.1 14.1 14.1h24.6c7.8 0 14.1-6.3 14.1-14.1s-6.3-14.1-14.1-14.1zM297.65 423.6h-24.6c-7.8 0-14.1 6.3-14.1 14.1s6.3 14.1 14.1 14.1h24.6c7.8 0 14.1-6.3 14.1-14.1s-6.3-14.1-14.1-14.1zM370.55 423.6h-24.6c-7.8 0-14.1 6.3-14.1 14.1s6.3 14.1 14.1 14.1h24.6c7.8 0 14.1-6.3 14.1-14.1s-6.3-14.1-14.1-14.1zM443.45 423.6h-24.6c-7.8 0-14.1 6.3-14.1 14.1s6.3 14.1 14.1 14.1h24.6c7.8 0 14.1-6.3 14.1-14.1s-6.3-14.1-14.1-14.1z" />
                    </svg>
                  </span>
                  <span>
                    <a
                      className="font-Lato text-base text-themeGray-200 hover:text-themeAccent transition delay-150 duration-300 ease-in-out"
                      href="tel:516-505-0044"
                    >
                      516.505.0022
                    </a>
                  </span>
                </div>
                <div className="flex items-center pb-4">
                  <span className="pr-5 text-[#666c84]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 490 490"
                      width="1rem"
                      height="1rem"
                      className="fill-current"
                    >
                      <path d="M479.574 78.191H10.425c-.071 0-.141.007-.211.008l.001.003C4.556 78.315 0 82.925 0 88.617v312.766c0 5.762 4.669 10.426 10.425 10.426h469.149c5.758 0 10.426-4.664 10.426-10.426V88.617c0-5.763-4.668-10.426-10.426-10.426zm-25.167 20.852l-138.581 138.58-.006.005L245 308.449l-70.82-70.821-.013-.012L35.594 99.043h418.813zM20.851 113.784l131.215 131.215L20.851 376.215V113.784zm14.743 277.173l131.215-131.216 70.821 70.821c3.629 3.629 9.303 5.439 14.743 0l70.82-70.82 131.215 131.215H35.594zm433.555-14.744L337.935 244.999l131.214-131.214v262.428z" />
                    </svg>
                  </span>
                  <span>
                    <a
                      className="font-Lato text-base text-themeGray-200 hover:text-themeAccent transition delay-150 duration-300 ease-in-out"
                      href="mailto:info@pomareslawgroup.com"
                    >
                      info@pomareslawgroup.com
                    </a>
                  </span>
                </div>
                <div className="flex flex-col items-start justify-start mt-5">
                  <h3 className="font-PTSerif font-bold text-xl text-black pb-3">
                    Nassau Office
                  </h3>
                  <div>
                    95 Front St
                    <br />
                    Hempstead, NY 11550
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start mt-5">
                  <h3 className="font-PTSerif font-bold text-xl text-black pb-3">
                    Suffolk Office -{" "}
                    <span className="italic">
                      {intl.formatMessage({ id: "Appointments Only" })}
                    </span>
                  </h3>
                  <div>
                    357 Veterans Memorial Hwy
                    <br />
                    Commack, NY 11725
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-5 lg:mt-0 mx-auto font-Montserrat text-themeGray-300">
            <h2 className="font-PTSerif font-bold text-5xl text-black pb-5">
              {intl.formatMessage({ id: "Get in Touch" })}
            </h2>
            <form
              className="text-gray-700 contact-form flex flex-col justify-center align-center font-Lato text-base"
              onSubmit={async event => {
                event.preventDefault()

                // ReCaptcha verification
                if (!executeRecaptcha) {
                  return
                }
                // This is the same as grecaptcha.execute on traditional html script tags
                const result = await executeRecaptcha("pomares_contact")
                setToken(result)
                console.log("received token: " + result)

                if (result.length <= 0) {
                  console.log("recaptcha failed, form not submitted")
                  return
                }

                const myForm = event.target
                const formData = new FormData(myForm)
                formData.append("_wpcf7_recaptcha_response", result)
                // Display the key/value pairs
                for (var pair of formData.entries()) {
                  console.log(pair[0] + ", " + pair[1])
                }
                setFormResp({ ...formResp, status: "loading" })

                axios
                  .post(
                    "https://pomareslawgroup.com/blog_admin/wp-json/contact-form-7/v1/contact-forms/246/feedback",
                    formData
                  )
                  .then(function (response) {
                    if (response.data.status === "mail_sent") {
                      setFormResp({
                        ...formResp,
                        status: "success",
                        response: response.data.response,
                        body_response: response.data.body_response,
                      })
                      console.log(response)
                    } else {
                      setFormResp({
                        ...formResp,
                        status: "error",
                        response: response.data.response,
                        body_response: response.data.body_response,
                      })
                      console.log(response)
                    }
                  })
                  .catch(function (error) {
                    setFormResp({
                      ...formResp,
                      status: "error",
                      response: "",
                      body_response: "Unknown error, please try again later.",
                    })
                    console.log(error)
                  })
              }}
            >
              <div className="font-Montserrat text-xs text-red-600 pb-5">
                {intl.formatMessage({ id: "Required" })}
                {"  *"}
              </div>
              <div className="grid gap-6 grid-cols-1 lg:grid-cols-2">
                <div className="relative col-start-1 col-end-2 row-start-1 row-end-2">
                  <input
                    id="your-name"
                    name="your-name"
                    type="text"
                    className="peer h-10 w-full text-gray-900 placeholder-transparent focus:outline-none focus:border-themeBlue-200"
                    placeholder={intl.formatMessage({ id: "Name" })}
                    required
                    autoComplete="name"
                    value={nameValue}
                    onChange={event => {
                      setNameValue(event.target.value)
                    }}
                  />
                  <label
                    htmlFor="your-name"
                    className="absolute left-2 -top-5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-5 peer-focus:text-gray-600 peer-focus:text-sm"
                  >
                    {intl.formatMessage({ id: "Name" })}
                  </label>
                  <span className="absolute right-2 top-1 text-red-600">*</span>
                </div>
                <div className="relative col-start-1 col-end-2 row-start-2 row-end-3 lg:col-start-2 lg:col-end-3 lg:row-start-1 lg:row-end-2">
                  <input
                    id="your-email"
                    name="your-email"
                    type="email"
                    className="peer h-10 w-full text-gray-900 placeholder-transparent focus:outline-none focus:border-themeBlue-200"
                    placeholder={intl.formatMessage({ id: "Email" })}
                    required
                    autoComplete="email"
                    value={emailValue}
                    onChange={event => {
                      setEmailValue(event.target.value)
                    }}
                  />
                  <label
                    htmlFor="your-email"
                    className="absolute left-2 -top-5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-5 peer-focus:text-gray-600 peer-focus:text-sm"
                  >
                    {intl.formatMessage({ id: "Email" })}
                  </label>
                  <span className="absolute right-2 top-1 text-red-600">*</span>
                </div>
                <div className="relative col-start-1 col-end-2 row-start-3 row-end-4 lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
                  <textarea
                    id="your-message"
                    name="your-message"
                    type="textarea"
                    rows="6"
                    className="peer w-full text-gray-600 placeholder-transparent focus:outline-none focus:border-themeBlue-200"
                    placeholder={intl.formatMessage({ id: "Message" })}
                    required
                    autoComplete="message"
                    value={messageValue}
                    onChange={event => {
                      setMessageValue(event.target.value)
                    }}
                  />
                  <label
                    htmlFor="your-message"
                    className="absolute left-2 -top-5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-5 peer-focus:text-gray-600 peer-focus:text-sm"
                  >
                    {intl.formatMessage({ id: "Message" })}
                  </label>
                  <span className="absolute right-2 top-1 text-red-600">*</span>
                </div>
              </div>
              <div className="w-full flex gap-x-8">
                <button
                  type="submit"
                  className="w-8/12 text-white bg-themeAccent hover:bg-themeAccentDarker focus:bg-themeAccentDarker font-Lato font-bold text-base px-8 py-2 mt-5 transition delay-150 duration-300 ease-in-out"
                >
                  {intl.formatMessage({ id: "Send" })}
                </button>
                <button
                  type="reset"
                  className="w-4/12 text-white bg-black hover:bg-[#333333] focus:bg-[#333333] font-Lato font-bold text-base px-8 py-2 mt-5 transition delay-150 duration-300 ease-in-out"
                  onClick={() => {
                    setNameValue("")
                    setEmailValue("")
                    setMessageValue("")
                  }}
                >
                  {intl.formatMessage({ id: "Reset" })}
                </button>
              </div>
            </form>
            <div id="response-message" style={{ padding: "20px" }}>
              {formResp.status === "loading" && (
                <p className="font-Lato text-black">Sending....</p>
              )}
              {formResp.status === "error" && (
                <p className="font-Lato text-red-600">
                  {formResp.body_response}
                </p>
              )}
              {formResp.status === "success" && (
                <p className="font-Lato text-green-600">
                  Your form has been submitted successfully, thank you.
                </p>
              )}
              <p className="text-white">
                {formResp.status === "success" &&
                  setTimeout(() => {
                    setFormResp("")
                    setNameValue("")
                    setEmailValue("")
                    setMessageValue("")
                  }, 3000)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      slug
      seo {
        fullHead
        schema {
          raw
        }
      }
    }
    bgImage: file(
      name: { regex: "/-banner/" }
      relativeDirectory: { eq: "contact" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          width: 1400
          placeholder: BLURRED
          quality: 90
          formats: [AUTO, WEBP]
        )
      }
    }
  }
`
